<template>
  <q-dialog
    ref="dialog"
    persistent
    class="sd-dialog--md"
    v-bind="{...$attrs, ...(dialogProps || {})}"
  >
    <q-card class="q-pa-md create-card-border">
      <q-toolbar>
        <q-toolbar-title class="text-capitalize">
          <span
            v-if="name !== 'scheduleTemporary'"
            class="text-weight-bold"
          >
            {{ $t('create.heading') }}
          </span>
          <span
            v-if="name === 'scheduleTemporary'"
            class="text-weight-bold"
          >{{ $t('create.changeHeading') }}</span> {{ displayName }}
        </q-toolbar-title>
        <preset-q-btn
          flat
          round
          dense
          icon="close"
          @click="hide()"
        />
      </q-toolbar>
      <q-card-section
        v-if="description"
        class="q-px-lg q-py-sm text-subtitle1 "
      >
        {{ description }}
      </q-card-section>
      <q-form
        @submit="onSubmit"
        @reset="onReset"
      >
        <q-card-section class="row fit">
          <div
            v-for="(contents, index) in list"
            :key="index"
            :class="listStyles(contents)"
            class="q-px-sm"
          >
            <q-input
              v-if="contents === 'name'"
              v-model="content[contents]"
              filled
              :label="$t('create.fields.name')"
              :rules="[val => !!val || 'Field is required']"
              debounce="1000"
            />
            <q-select
              v-else-if="contents === 'type'"
              v-model="content[contents]"
              filled
              emit-value
              map-options
              :options="options"
              :rules="[val => !!val || 'Field is required']"
              :label="$t('create.fields.type')"
            />
            <q-input
              v-else-if="contents === 'mediaType'"
              v-model="content[getCurrentMediaType.slug]"
              filled
              :label="getCurrentMediaType.label"
            />
            <q-input
              v-else-if="contents === 'slug'"
              v-model="content[contents]"
              filled
              :label="$t('create.fields.slug')"
              debounce="1000"
              :rules="[checkIfSlugExists]"
            />
            <q-input
              v-else-if="contents === 'date'"
              v-model="content[contents]"
              filled
              mask="####-##-##"
              placeholder="YYYY-MM-DD"
            >
              <template #append>
                <q-icon
                  name="calendar_today"
                  class="cursor-pointer"
                >
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="content[contents]"
                      mask="YYYY-MM-DD"
                      @update:model-value="() => $refs.qDateProxy[0].hide()"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-input
              v-else-if="contents === 'dateStart'"
              v-model="content[contents]"
              filled
              reactive-rules
              :rules="[val => content.dateStart !== undefined || 'Start date is required',
                       val => !!val || 'Start date is required',
                       val => isoIsBeforeOrEqual(val, content.dateEnd, { zone: $store.getters['adminapp/getTimeZone'] }) || 'Start date is set past end date']"
              :hint="$t('create.fields.startDate')"
            >
              <template #append>
                <q-icon
                  name="calendar_today"
                  class="cursor-pointer"
                >
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="content[contents]"
                      mask="MM/DD/YYYY"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-input
              v-else-if="contents === 'dateEnd'"
              v-model="content[contents]"
              filled
              reactive-rules
              :rules="[val => content.dateEnd !== undefined || 'End date is required',
                       val => !!val || 'End date is required',
                       val => isoIsAfterOrEqual(val, content.dateStart ? content.dateStart : content.dateEnd, { zone: $store.getters['adminapp/getTimeZone'] }) || 'End date is older than start date',
              ]"
              :hint="$t('create.fields.endDate')"
            >
              <template #append>
                <q-icon
                  name="calendar_today"
                  class="cursor-pointer"
                >
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="content[contents]"
                      mask="MM/DD/YYYY"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-input
              v-else-if="contents === 'time'"
              v-model="content[contents]"
              filled
              :hint="$t('create.fields.streamingTime')"
              placeholder="--:--"
              mask="time"
            >
              <template #append>
                <q-icon
                  name="schedule"
                  size="xs"
                  class="cursor-pointer"
                >
                  <q-popup-proxy
                    ref="streamTimeProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-time
                      v-model="content[contents]"
                      @update:model-value="$refs.streamTimeProxy[0].hide()"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <asset-upload
              v-else-if="contents === 'image'"
              ref="assetUpload"
              class="q-pt-md"
              flat
              :show-header="false"
              :show-actions="false"
              :show-input="false"
              :outlined="false"
              :is-multiple="false"
              filled
              dense
            />
            <IABKeywordSelector
              v-else-if="contents === 'tags'"
              :content="content"
              :iab-categories="[]"
              :show-blocked="false"
              :outlined="false"
              :filled="true"
              @update:tags="content[contents] = $event"
            />
            <campaign-schedule
              v-else-if="contents === 'impression'"
              :date-start="localCampaignSchedule.dateStart"
              :date-end="localCampaignSchedule.dateEnd"
              :recurring-type="localCampaignSchedule.recurringType"
              :impressions-per-period="localCampaignSchedule.impressionsPerPeriod"
              @update:recurring-type="localCampaignSchedule.recurringType = $event"
              @update:impressions-per-period="localCampaignSchedule.impressionsPerPeriod = $event"
              @update:date-start="localCampaignSchedule.dateStart = $event"
              @update:date-end="localCampaignSchedule.dateEnd = $event"
            />
            <q-input
              v-else
              v-model="content[contents]"
              class="q-pb-md"
              filled
              :label="$t(`create.fields.${contents}`)"
            />
          </div>
          <div class="q-pa-sm col-12 row justify-center">
            <preset-q-btn
              :label="$t('cta.cancel')"
              text-color="bg-default"
              rounded
              unelevated
              class="q-ml-sm bg-default-tertiary q-mr-md col-3"
              @click="$emit('close');hide()"
            />
            <preset-q-btn
              :label="$t('cta.submit')"
              type="submit"
              rounded
              color="primary"
              class="col-3"
              unelevated
              :disable="!isValid"
            />
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </q-dialog>
</template>
<script>

import { isoIsBeforeOrEqual, isoIsAfterOrEqual } from 'src/utils/date'
import createBlock from 'src/utils/clone-block'
import CampaignSchedule from 'src/components/Campaign/CampaignSchedule.vue'
import handleApiError from 'src/utils/handle-api-error'
import cloneDeep from 'lodash/cloneDeep'
import { usePresetDateTime } from 'src/composables/use-preset-datetime'
import { distributionTypeOptions, productionTypeOptions, productionMediaType } from 'src/utils/constants'
import { useResourceDialog } from 'src/composables/use-dialog'
import { ref } from 'vue'
import AssetUpload from 'src/components/Assets/AssetUpload.vue'
import IABKeywordSelector from 'src/components/IABKeywordSelector.vue'
import { storeTagsFn, removeTagsFn } from 'src/composables/use-experience-data-editor'
import set from 'lodash/set'

const fullWidthFields = ['name', 'impression', 'image', 'tags']

const defaultLocalCampaignSchedule = {
  dateStart: '',
  dateEnd: '',
  recurringType: 'day',
  impressionsPerPeriod: 10000,
  priority: 0
}

export default {
  components: { CampaignSchedule, AssetUpload, IABKeywordSelector },
  props: {
    name: { type: String, default: null },
    value: { type: Boolean, default: false },
    config: { type: Object, default: function () { return {} } },
    noRedirect: { type: Boolean, default: false },
    dialogProps: Object
  },
  emits: ['ok', 'close'],
  setup (props, context) {
    const { presetDateTime } = usePresetDateTime()
    const dialog = ref(null)
    return { dialog, presetDateTime, ...useResourceDialog({ dialog }, context) }
  },
  data () {
    return {
      content: {},
      options: this.name === 'production' ? productionTypeOptions : distributionTypeOptions,
      isValid: true,
      isSlug: true,
      localCampaignSchedule: cloneDeep(defaultLocalCampaignSchedule)
    }
  },
  computed: {
    getCurrentMediaType () {
      return productionMediaType.find(i => i.type === this.content.type)
    },
    imgSrc () {
      return import.meta.env.VITE_CDN_BASE_URL + '/uploads/source-logo.png'
    },
    description () {
      let desc
      switch (this.name) {
        case 'content':
          desc = this.$t('create.content.tagline')
          break
        case 'production':
          break
        case 'activation':
          desc = this.$t('create.metacontent.tagline')
          break
        case 'liveProduction':
          desc = this.$t('create.live.tagline')
          break
        case 'campaign':
          desc = this.$t('create.campaign.tagline')
          break
        case 'interactive':
          desc = this.$t('create.interactive.tagline')
          break
        default:
          desc = `${this.name} creation... Once finished, you will be redirected to the editor.`
          break
      }
      return desc
    },
    displayName () {
      let name
      switch (this.name) {
        case 'content':
          name = this.$t('create.content.content')
          break
        case 'production':
          name = this.$t('create.production.content')
          break
        case 'liveProduction':
          name = this.$t('create.live.content')
          break
        case 'activation':
          name = this.$t('create.metacontent.content')
          break
        case 'campaign':
          name = this.$t('create.campaign.content')
          break
        case 'interactive':
          name = this.$t('create.interactive.content')
          break
        default:
          name = `${this.name}`
          break
      }
      return name
    },
    // generate list
    list () {
      let list = []
      switch (this.name) {
        case 'content':
          list = ['name', 'slug', 'media', 'type']
          break
        case 'production':
          list = ['name', 'type', 'mediaType', 'image', 'tags']
          break
        case 'activation':
          list = ['name', 'slug']
          break
        case 'campaign':
          list = ['name', 'impression']
          break
        case 'liveProduction':
          list = ['name', 'slug', 'date', 'time', 'media', 'type']
          break
        case 'interactive':
          list = ['name']
          break
      }
      return list
    },
    listStyles () {
      return field => {
        if (fullWidthFields.includes(field)) return 'col-12'
        else return 'col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12'
      }
    },
    currentDate () {
      return this.presetDateTime(new Date().toISOString()).toISODate()
    }
  },
  mounted () {
    if (this.list.includes('type')) this.content.type = this.options[0]?.value
    this.$watch('content.name', value => {
      const slug = this.slugify(value)
      if (slug) {
        this.$set(this.content, 'slug', slug)
      }
    }, { immediate: true })
    this.setDefaultDate()
  },
  methods: {
    isoIsBeforeOrEqual,
    isoIsAfterOrEqual,
    slugify (slug = '') {
      return slug.toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    },
    async checkIfSlugExists (v) {
      if (v === null || typeof v === 'undefined' || v === '') return true
      let res
      if (this.name === 'activation') {
        res = await this.$api.get('/activations/count', { params: { slug_eq: v } })
      } else if (this.name === 'production') {
        res = await this.$api.get('/productions/count', { params: { slug_eq: v } })
      } else {
        res = await this.$api.get('/distributions/count', { params: { slug_eq: v } })
      }
      return !res.data || 'This slug is already taken'
    },
    // capitalizing first letter... just for looks
    capitalize (str) {
      return str.replace(/^\w/, c => c.toUpperCase())
    },
    // handles sumbit and rerouting
    async onSubmit () {
      this.$q.notify({
        color: 'green-4',
        textColor: 'white',
        icon: 'cloud_done',
        message: 'Submitting...'
      })
      let content = { ...this.content, creator: this.$store.state.user.email }
      if (this.name === 'content' && this.config?.production?.id) {
        const newData = this.config.production.data
        set(newData, 'timeline.activations.disableInheritance', true)
        content = { ...content, production: this.config.production, data: newData }
      }
      if (this.name === 'liveProduction') {
        content = { ...content, settings: { streamingDate: this.content.date, streamingTime: this.content.time }, forStreamingSetting: true }
      }
      if (this.name === 'activation') {
        const { data } = await this.$api('/apps?type=Activation&slug=smartblock-display')
        const displayBlock = createBlock(data[0].settings, { ring: { show: false } })
        content = { ...content, settings: { preview: { template: [displayBlock] } }, version: 2 }
      }
      if (this.name === 'campaign') content = { ...content, active: true, dateStart: this.localCampaignSchedule.dateStart, dateEnd: this.localCampaignSchedule.dateEnd }
      if (this.config?.folder?.id) content = { ...content, parent: this.config.folder.id }
      let location
      switch (this.name) {
        case 'content':
          location = '/distributions'
          break
        case 'production':
          location = '/productions'
          break
        case 'activation':
          location = '/activations'
          break
        case 'campaign':
          location = '/campaigns'
          break
        case 'liveProduction':
          location = '/distributions'
          break
        case 'interactive':
          location = '/interactives'
          break
      }
      if (this.name === 'production') {
        const imgRes = await this.$refs.assetUpload[0].onUploadAssets()
        if (imgRes) content.coverImage = imgRes[0]
      }
      const response = await handleApiError(this.$api.post(location, content))
      this.$q.notify({
        color: 'green-4',
        textColor: 'white',
        icon: 'cloud_done',
        message: 'Redirecting...'
      })
      this.debug('DATA', response.data)
      const { id } = response.data
      if (this.name === 'production') {
        const tagsData = content.tags?.map?.(entry => {
          return {
            ...entry,
            tag: entry.id,
            resource: id
          }
        }) || []
        if (content?.tags) await removeTagsFn({ resourceType: 'productions', id })
        if (tagsData.length > 0) await storeTagsFn({ tagsData, resourceType: 'productions', id })
      }
      if (this.name === 'campaign') await this.$api.post('/campaign-schedules', { ...this.localCampaignSchedule, campaign: response.data })
      this.$emit('close')
      this.$emit('ok')
      if (!this.noRedirect) {
        if (this.name === 'activation') {
          this.$router.push({ name: 'edit-' + this.name, params: { id, activeTab: 'ChooseTemplate' }, query: { view: 'builder', ...this.config?.query } })
        } else {
          this.$router.push({ name: 'edit-' + this.name, params: { id } })
        }
      }
      this.hide()
    },
    setDefaultDate () {
      const dateStart = new Date()
      const dateEnd = new Date()
      dateStart.setDate(dateStart.getDate() + 1)
      dateEnd.setDate(dateStart.getDate() + 2)
      this.localCampaignSchedule.dateStart = dateStart.toISOString()
      this.localCampaignSchedule.dateEnd = dateEnd.toISOString()
    },
    // reset
    onReset () {
      this.content = {}
      this.localCampaignSchedule = cloneDeep(defaultLocalCampaignSchedule)
    }
  }
}
</script>
