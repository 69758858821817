import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import appDebug from 'src/utils/debug'
import { nextTick } from 'vue'

const debug = appDebug.extend('router')

export default function ({ app, store }) {
  const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,
    base: import.meta.env.VITE_VUE_ROUTER_BASE
  })

  app.use(router)

  // TODO [Migration] update to router 4

  router.beforeEach((to, from, next) => {
    debug(`Router: Checking route: ${from.name} -> ${to.name}`)
    // If the app isn't loaded, show them a loading screen...
    if (to.matched.some(record => record.meta.requiresLogin) && !store.getters['auth/isLoggedIn']) {
      debug('Router: Auth check...')
      next({ name: 'login', query: { redirect: to.path, query: JSON.stringify(to.query) } })
      // No login requirement? Let them visit...
    } else if (to.name === 'login' && store.getters['auth/isLoggedIn']) {
      next({ name: 'home' })
    } else if (to.name === 'home') {
      const customRoute = store.getters['adminapp/getCurrentTenantSettings']('defaultPage', null)
      // Check for custom default route using the getter
      if (customRoute) next({ name: customRoute })
      else next()
    } else {
      debug('Router: Routing...')
      next()
      // FIXME: this may or may not be a temp fix, using $route does not update on layout, this is a work around
      store.dispatch('adminapp/updateRoute', { to, from })
    }
  })
  router.afterEach((to, from) => {
    nextTick(() => {
      document.title = to.meta.title || 'Sourcesync.io Administration Application'
    })
  })

  store.$router = router
  return router
}
