// This is just an example,
// so you can safely delete all default props below

export default {
  short: {
    year: 'numeric', month: 'short', day: 'numeric'
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  },
  home: {
    title: 'Welcome to Kurator',
    tagline: 'The Next Evolution in Digital Content! In this Admin you can enhance all your existing and new content with layers of interactivity and engagement. Let\'s get building!'
  },
  register: {
    title: 'Start your journey',
    caption: 'First month free. No credit card required, no software to download.'
  },
  resources: {
    metacontent: 'Activation | Activations',
    content: 'Distribution'
  },
  failed: 'Action failed',
  success: 'Action was successful',
  dashboard: {
    topMetacontent: {
      title: 'Top Activation',
      subTitle: 'A breakdown of the top performing meta-content across all of your content.'
    }
  },
  login: {
    tagline: 'Media\'s New Currency',
    caption: '',
    email: 'Email',
    password: 'Password',
    success: 'Login successful',
    ssoError: 'An error occurred while processing your login request.',
    errors: {
      400: 'Please Check your credentials',
      429: 'You are trying to login too often. Please wait a moment before trying again.',
      default: 'Login error',
      orgFail: 'Access Denied: Please contact your admin to connect your account to an organization.'
    }
  },
  search: {
    bar: {
      text: 'Search'
    },
    results: 'Search Results'
  },
  // Invite is actually being used in the invite components so don't delete!
  // Keys are named the same as their respective components in src/invite
  invite: {
    header: 'Invite Users',
    listItemAction: {
      inviteUsers: 'Invite Users',
      cancelAction: 'Cancel'
    },
    listItem: {
      userToInvite: 'Username or email address',
      role: 'Role'
    },
    splash: {
      header: 'Congratulations!',
      subHeader: 'Users invited successfully.',
      homeBtn: 'Home'
    }
  },
  cta: {
    editSelected: 'Edit Selected',
    publish: 'Publish',
    draft: 'Draft',
    cancel: 'Cancel',
    stay: 'Stay here',
    save: 'Save',
    batchUpdate: 'Batch Update',
    batchUpdateSuccess: 'Updated <%= numRecords %> records',
    saveAs: 'Save As',
    saveAndOpen: 'Save and Open',
    existing: 'Existing SAMs',
    upload: 'Upload',
    add: 'Add',
    deleteSelected: 'Delete Selected',
    allow: 'Allow',
    block: 'Block',
    removeFromListAllow: 'Remove from Allow List',
    removeFromListBlock: 'Remove from Block List',
    addDist: 'Add Distributions',
    addSelect: 'Add Selected',
    addSelected: 'Add Selected Items ({items})',
    approveSelected: 'Allow Selected Items ({items})',
    blockSelected: 'Block Selected Items ({items})',
    ok: 'Ok',
    create: 'Create',
    newFolder: 'Add Folder',
    refresh: 'Refresh',
    submit: 'Submit',
    complete: 'Complete',
    finish: 'Finish',
    reset: 'Reset',
    return: 'Return',
    enable: 'Enable',
    disable: 'Disable',
    previous: 'Previous',
    next: 'Next',
    quit: 'Quit',
    continueSync: 'Continue Sync',
    linkYourChannel: 'Link Your Channel',
    enableAutoSync: 'Enable the Auto-Sync',
    disableAutoSync: 'Disable the Auto-Sync',
    changePassword: 'Update',
    updateUserInfo: 'Update User Info',
    addPhoto: 'Add Photo',
    chooseTemplate: 'Choose template',
    changeTemplate: 'Change template',
    select: 'Select',
    selectFrom: 'Select from...',
    selectAnother: 'Select Another Activation',
    selectAll: 'Select All',
    clear: 'Clear',
    playAnim: 'Play Exit/Enter',
    selectAssets: 'Select from Assets',
    addBeacon: 'Add Beacon',
    filter: 'Filter',
    removeFilter: 'Remove Filter',
    goLive: 'Go Live',
    endLive: 'End Live Session',
    addMetacontent: 'Add Activations',
    forgotPassword: 'Forgot password?',
    signIn: 'Sign in',
    login: 'Login',
    backToLogin: 'Back to Login Page',
    today: 'Today',
    download: 'Download',
    copyLink: 'Copy Link',
    clone: 'Clone',
    vastTagTester: 'VAST Tag Tester',
    copy: 'Copy',
    resyncVideos: 'Re-Sync Videos',
    addYourVideos: 'Add Your Videos',
    createNewTarget: 'Create New Targeting Rule',
    continue: 'Continue',
    continueToTarget: 'Continue to Targeting',
    back: 'Back',
    returnToDist: 'Return to Distribution',
    temporarySchedule: 'Add Temporary Schedule',
    updateTemporarySchedule: 'Update Temporary Schedule',
    overwriteButtonLabel: 'Select Campaigns / Distributions to Overwrite',
    confirmSelection: 'Confirm Selection',
    backToProd: 'Go to Production',
    seeAll: 'See All',
    goToSettings: 'Back',
    createTargetRule: 'Create Targeting Rule',
    edit: 'Edit',
    backToList: '{name} List',
    backToOverview: '{name} Overview',
    cloneProperty: 'Clone Property',
    send: 'Send',
    goToCampaign: 'Go to Campaign',
    goToProduction: 'Go to Production',
    goToActivation: 'Go to Activation Builder',
    previewActivation: 'Go to Activation Preview',
    remove: 'Remove',
    duplicateDistribution: 'Duplicate Distribution',
    export: 'Export',
    exit: 'Exit',
    close: 'Close',
    downloadEULA: 'Download the SourceSync EULA',
    eulaReviewTerms: 'Review the terms again by clicking here'
  },
  validationRules: {
    required: 'Required*',
    optional: 'Optional',
    warning: 'Warning',
    empty: 'Field cannot be left empty',
    autoPlay: 'There are 2 or more video blocks set to autoplay'
  },
  notifications: {
    formError: 'There are some errors in the form',
    saveSuccessful: 'Saved successfully!',
    createdSuccessful: 'Created successfully!',
    warning: 'Warning!',
    unsupportedFile: 'This file is not supported.',
    error: 'An error occurred',
    copyLinkSuccess: 'Copied link!',
    copyLinkFail: 'Could not copy link...',
    deleteSucess: 'Deleted successfully',
    confirm: 'Confirm',
    fileSize: '{name} size is larger than {size}',
    bannerSize: 'Banner image size is larger than {size}. It may not display correctly.',
    confirmDelete: 'Confirm Delete',
    confirmEndMsg: 'Are you sure you want to end the stream?',
    confirmDelMsg: 'Are you sure you want to delete{name}?',
    confirmMoveMsg: 'Are you sure you want to move {name}',
    confirmCloneMsg: 'Are you sure you want to clone {name}',
    confirmDuplicate: 'Are you sure you want to duplicate {name}',
    confirmDownload: 'Are you sure you want to download {name}',
    failedToDeleteFolder: 'This folder cannot be deleted because it contains items.',
    deleteFolder: 'Are you sure you want to delete {name}?',
    registerSuccess: 'Registration successful. You will receive an email to verify your account.',
    mobileWarning: 'Current view may not reflect actual size.',
    cloneSuccessful: 'Successfully cloned to {name}',
    noPause: 'Cannot pause ended Campaign',
    savedAssets: 'The assets were successfully added to the library',
    userAdded: 'User is added'
  },
  status: {
    notStarted: 'Not Started',
    live: 'Live',
    schedule: 'Scheduled',
    ended: 'Ended',
    paused: 'Paused',
    status: 'Status'
  },
  style: {
    style: 'Style',
    width: 'Width',
    height: 'Height',
    value: 'Value',
    mode: 'Mode/Unit',
    iFrameWidth: 'Iframe Width',
    iFrameHeight: 'Iframe Height'
  },
  templateLocations: {
    categories: {
      onVideo: 'On Video',
      outsideVideo: 'Outside Video'
    },
    locations: {
      off: 'Off',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      inside: 'Inside Positions',
      left: 'Left',
      topBottom: 'Top Bottom',
      leftRight: 'Left Right',
      outTop: 'Outer Top',
      outRight: 'Outer Right',
      outBottom: 'Outer Bottom',
      outLeft: 'Outer Left',
      outTopRight: 'Outer Top Right',
      outRightBottom: 'Outer Right Bottom',
      outBottomLeft: 'Outer Bottom Left',
      outLeftTop: 'Outer Left Top'
    }
  },
  metacontent: {
    timeline: {
      heading: 'Timeline',
      tagline: 'Insert Activations into the timeline of the Content.',
      view: 'Confidence View',
      hide: 'Hide Timeline',
      show: 'Show Timeline'
    },
    sam: 'Served Available Market (SAM)',
    currentTemplate: 'Current Template',
    events: 'Events',
    keywords: 'Keywords',
    iabCategories: 'IAB Categories'
  },
  layout: {
    menu: 'Menu',
    help: 'Help',
    account: 'Account',
    organization: 'Organization',
    contentWallet: 'Content Wallet',
    login: 'Login',
    logout: 'Log Out',
    property: 'Active Property',
    propertySettings: 'Property Settings',
    kurating: 'Kurating - {name}'
  },
  folders: {
    heading: 'Folders',
    rename: 'Rename Folder',
    delete: 'Delete Folder',
    create: 'Create New Folder',
    folderName: 'Folder Name'
  },
  resourceTable: {
    create: {
      metacontent: 'Create New Activation',
      content: 'Create New Distribution',
      campaign: 'Create New Campaign',
      liveProduction: 'Create New Live Production',
      interactive: 'Create New Interactive',
      production: 'Create New Production'
    },
    headers: {
      display: 'Display',
      type: 'Type',
      name: 'Name',
      campaignName: 'Campaign Name',
      link: 'Link',
      updated: 'Updated',
      updatedBy: 'Updated By',
      created: 'Created',
      createdBy: 'Created By',
      folder: 'Folder',
      slug: 'Slug',
      sortAlphabet: 'Sort Alphabetically',
      id: 'ID',
      publishState: 'Status: {state}',
      published: 'Published',
      draft: 'Draft',
      template: 'Template',
      action: 'Action',
      preview: 'Preview',
      metacontents: 'SAMs',
      home: 'Home',
      contents: 'Productions',
      status: 'Status',
      lastUpdated: 'Last updated {time}',
      impressions: 'Impressions',
      engagements: 'Clicks',
      trend: 'Trend',
      content: {
        settings: 'Settings',
        data: 'Data',
        published: 'Published',
        draft: 'Draft'
      },
      metacontent: {
        previewText: 'Call to Action Text',
        start: 'Start',
        end: 'End',
        duration: 'Duration',
        position: 'Position',
        static: 'Static',
        active: 'Active',
        template: 'Template'
      },
      asset: {
        uploaded: 'Uploaded',
        lastUpdated: 'Last Updated',
        format: 'Format',
        dimensions: 'Dimensions',
        size: 'Size',
        description: 'Description'
      },
      campaign: {
        startDate: 'Start Date',
        endDate: 'End Date',
        spent: 'Spent',
        status: 'Status',
        impressions: 'Impressions',
        engagements: 'Clicks'
      }
    },
    menu: {
      move: 'Move',
      delete: 'Delete',
      duplicate: 'Duplicate',
      generate: 'Generate',
      download: 'Download',
      copyLink: 'Copy Link',
      cloneToOrg: 'Clone to Org',
      moveToProject: 'Move to Project',
      moveToORG: 'Move to ORG',
      moveToFolder: 'Move to Folder',
      replaceAsset: 'Replace Asset'
    },
    labels: {
      noData: 'No data available',
      noResults: 'No matched records',
      loading: 'Loading...',
      rowsPerPage: 'Records per page'
    },
    filter: {
      filtered: 'Filtered by selected records.',
      singleRecord: '1 record is currently selected.',
      multipleRecords: 'records are currently selected.',
      filteredByOne: '1 record selected.',
      filteredByMore: ' records selected.'
    },
    selected: 'Selected:'
  },
  create: {
    heading: 'Create',
    changeHeading: 'Change',
    fields: {
      name: 'Name',
      slug: 'Slug',
      media: 'Media',
      type: 'Type',
      streamingDate: 'Streaming Date',
      streamingTime: 'Streaming Time',
      startDate: 'Start Date',
      endDate: 'End Date',
      interactive: 'Interactive',
      impressionDaily: 'Daily',
      impressionWeekly: 'Weekly',
      impressionUncapped: 'Uncapped',
      totalImpressions: 'Total Impressions',
      totalImpressionDay: 'Impressions per day',
      totalImpressionWeek: 'Impressions per week',
      durationEachSAM: 'Duration of each Activation',
      samsDisplayedAtTheSameTime: 'Activations displayed at the same time',
      durationBetweenSAMsInSeconds: 'Duration between Activations in seconds',
      theBegginingWithoutSAMs: 'Part of the distribution at the beginning without the Activations',
      theEndWithoutSAMs: 'Part of the distribution at the end without the Activations ',
      positionOfAllSAMs: 'Position of all Activations'
    },
    metacontent: {
      content: 'Activation',
      tagline: 'Once you filled out the form below and click Submit, a Activation will be created. You will then be redirected to the new Activation.'
    },
    content: {
      content: 'Distribution',
      tagline: 'Once you have filled out the form below, you will be taken to the next stage of the Distribution creation process.'
    },
    production: {
      content: 'Production',
      tagline: 'Once you have filled out the form below, you will be taken to the next stage of the Production creation process.'
    },
    live: {
      content: 'Live Production',
      tagline: 'Once you have filled out the form below, you will be taken to the next stage of the Live Production creation process.'
    },
    campaign: {
      content: 'Campaign',
      tagline: 'Once you have filled out the form below and click Submit, a campaign will be created. You will then be redirected to the Campaign Builder.'
    },
    interactive: {
      content: 'Interactive',
      tagline: 'Once you have filled out the form below and click Submit, a interactive will be created. You will then be redirected to the Interactive Builder.'
    },
    scheduleTemporary: {
      content: 'Schedule Temporarily',
      tagline: ''
    },
    template: {
      heading: 'Interactive Setting',
      subheading: 'Configure HTML Interactive',
      template: 'Template',
      iab: 'IAB Categories',
      blockiab: 'Blocked IAB Categories',
      impressionLabel: 'How do you want to run impressions?'
    }
  },
  pages: {
    home: {
      charts: {
        unknown: 'Unknown',
        nA: 'N/A',
        campaigns: 'Campaigns',
        utmSource: 'UTM Source',
        meta: 'Meta',
        activationId: 'ID',
        advertiserName: 'Advertiser',
        contentName: 'Name',
        metaName: 'Name',
        dist: 'ID',
        distName: 'Name',
        productionId: 'ID',
        sessionCount: 'Reach',
        engagementCount: 'Engagements',
        impressionCount: 'Impressions',
        actionCount: 'Actions',
        CTR: 'CTR',
        AR: 'AR',
        IAR: 'IAR',
        totalTOS: 'TotalTOS',
        avgTOS: 'AvgTOS',
        eventCount: 'Event',
        userCount: 'User',
        startCount: 'Start',
        playCount: 'Play',
        pauseCount: 'Pause',
        endCount: 'End',
        displayCount: 'Display',
        touchCount: 'Touch',
        clickOutCount: 'Click Out',
        videoClickOutCount: 'Video Click Out'
      }
    },
    pulse: {
      heading: 'Pulse Analytics',
      searchName: 'Enter your text'
    },
    assets: {
      heading: 'Assets',
      folderHeading: 'Projects',
      tagline: 'Manage your assets',
      uploadAssets: 'Upload Assets',
      uploadImages: 'Upload Images',
      replaceAssets: 'Replace Assets',
      drawer: {
        uploaded: 'Uploaded',
        lastUpdated: 'Last Updated',
        description: 'Description',
        title: 'Title',
        copyAssetLinkTooltip: 'Copy Asset Link',
        size: 'Size',
        dimensions: 'Dimensions',
        type: 'Type'
      }
    },
    metacontent: {
      heading: 'Activations',
      tagline: 'These are the activations you build and insert as drops in your work',
      copySAMLinkTooltip: 'Copy Activation link'
    },
    property: {
      heading: '{name} Details',
      doesNotExist: 'Image does not exist or was removed',
      installations: {
        embedCodes: 'Embed Codes',
        embedCode: 'Embed Code',
        codeSnippet: 'Code snippet',
        tags: 'Tags',
        addTag: 'Add Tag',
        embedderVersion: 'Embedder Version',
        copyEmbedCode: 'Copy Embed Code to Clipboard',
        querySelector: 'Query Selector for Content ID Element',
        settings: 'Settings',
        querySelectorHint: 'A CSS Selector used to determine the DOM element that contains your Content ID.',
        addNewEmbed: 'Add New Embed'
      },
      users: {
        users: 'Users',
        user: 'User',
        status: 'Status',
        dateAdded: 'Date Added',
        addUser: 'Add New User',
        usersEmail: 'User’s Email'
      }
    },
    editMetacontent: {
      heading: 'Editing',
      editTooltip: 'Edit the name',
      saveAs: 'Save as...',
      enterNewName: 'Enter new name:',
      copy: 'copy',
      open: 'Would you like to save current changes and open this Activation: ',
      editTab: {
        tabs: {
          template: 'Choose \n Template',
          overlay: 'Edit Activation \n Overlay',
          editMetacontent: 'Edit \n Activation'
        },
        metacontent: {
          currentTemplate: 'Current Template:',
          import: 'Import Blocks',
          importSelected: 'Import Selected Blocks',
          add: 'Add Blocks',
          copy: 'Copy From:',
          emptyBlock: 'This Activation has no blocks',
          enableBlock: 'Enable Block'
        },
        overlay: {
          overrideActivationBehaviors: 'Override Activation Level Behaviors',
          autoPlay: 'Autoplay',
          muted: 'Muted',
          contentWallet: 'Content Wallet',
          clickOutsideToCloseSam: 'Click Outside to Close Activation',
          fullscreenDetailsInMobilePortrait: 'Fullscreen Details In Mobile Portrait',
          playThroughDetails: 'Play Through Details',
          setBehaviors: 'Set Behaviors',
          forcePortraitOrientation: 'Below the Video',
          showPreviewThroughDetails: 'Show Preview Through Details',
          setLayoutCustomization: 'Set Layout Customization',
          previewText: 'Call to Action Text',
          display: 'Display Image',
          url: 'Image URL',
          selectAssets: 'Select from Assets',
          socialMedia: 'Social Media Networks',
          setSocial: 'Social Media Sharing',
          addNetwork: 'Add networks by clicking the "Add" button below.',
          customDesign: 'Customize Design',
          invertColors: 'Invert colors',
          setToBlack: 'Set network to black?',
          addAll: 'Add All',
          customPreview: 'Customize Preview',
          convertSmartblock: 'Convert to SmartBlocks',
          preview: 'SmartBlocks as Preview',
          previewTagline: 'Add SmartBlocks to the Activation Preview. When this is enabled, the Activation details can\'t be opened.',
          settings: 'Settings',
          imageSize: 'Image Size',
          iconBgColor: 'Icon Background Color',
          iconTextColor: 'Icon Text Color',
          bwIcons: 'Black and White icons',
          iconsShape: 'Shape:',
          closeBtnColor: 'Close button Color',
          bgColor: 'Background Color',
          closeBtnSize: 'Close button Size',
          networkIconSize: 'Social Network Size',
          background: 'Background',
          backdropColor: 'Backdrop Color',
          backdropPosition: 'Background Position',
          backdropRepeat: 'Background Repeat',
          opacity: 'Opacity',
          spaceBetween: 'Padding between blocks (in pixels)',
          borderWidth: 'Activation border width (in pixels)',
          textColor: 'Text Color',
          separatorColor: 'Separator Color',
          clickToDial: 'Click to dial',
          countrySelectorLabel: 'Country code',
          countrySelectorError: 'Choose a country',
          phoneNumberLabel: 'Phone Number',
          example: 'Example: ',
          warningMsgPreview: 'Current SmartBlock preview configuration will be lost. Would you like to proceed?',
          warningMsgDial: 'SmartBlocks will not be rendered when "Click to dial" is enabled.',
          setToAd: 'Set Ad Display Size',
          customIABCategories: 'Add and Remove Advertising and IAB Categories',
          setTrackingPixels: 'Set Tracking Pixels',
          advertName: 'Advertiser Name',
          advertId: 'Advertiser ID',
          dataTracking: 'Data Tracking',
          advertDisplaySize: 'Ad Display Size',
          setAdId: 'Set Advertiser ID/Name',
          selectSize: 'Select Size',
          selectIABCategories: 'Select IAB Categories',
          addCustomIABCategories: 'Add/Remove Custom Keywords',
          enterSize: 'Or Enter Size',
          forStandaloneOnly: '*This setting is for Standalone Activations only',
          disabled: 'This option is currently disabled. Enable it to see its settings.'
        }
      },
      previewTab: {
        preview: 'Preview',
        chooseTemplate: 'Choose Template',
        previewOverlay: 'Activation Preview Inside Video Window',
        previewPortrait: 'Activation Preview Under the Video',
        topBarSettings: 'Top Bar Settings',
        backgroundSettings: 'Background Settings'
      }
    },
    content: {
      heading: 'Distributions',
      liveHeading: 'Live Productions',
      tagline: 'Here are your content items within the platform',
      openContentLink: 'Go to Distribution link'
    },
    production: {
      heading: 'Productions',
      tagline: 'Here are your production items within the platform'
    },
    editProduction: {
      timeline: 'Timeline',
      productionDetails: 'Production Details'
    },
    projects: {
      heading: 'Projects',
      tagline: 'Here are your projects within the platform'
    },
    categories: {
      heading: 'Categories & Keywords',
      tagline: 'Here are your Categories & Keywords'
    },
    editContent: {
      editStaging: 'Edit Staging',
      editTimeline: 'Edit Timeline',
      mediaSrc: 'Media Source',
      ogMeta: 'OG Meta',
      ogMetaSettings: 'OG Meta Settings',
      ogMetaSettingsCaption: 'Look and Feel when sharing on social media or messaging apps',
      image: 'Image',
      cover: 'Cover Image',
      videoPreview: 'Video Preview',
      videoPinching: 'Video Squeezeback',
      impressionPixels: 'Impression Pixels',
      impressionPixelsTooltip: 'Track when video starts',
      embedCode: 'Embed Code',
      embedCodeCaption: 'Default script for embedding on websites and apps',
      vast: 'VAST',
      vastQuartilePixels: 'VAST Quartile Pixels',
      quartilePixels: 'Quartile Pixels',
      quartilePixelsTooltip: 'Track Quartile events',
      startLabel: 'Start Quartile',
      startPlaceholder: 'Enter Pixel URL',
      startHelperText: 'You may add up to 2 pixel URLs.',
      firstQuartileLabel: 'First Quartile',
      firstQuartilePlaceholder: 'Enter Pixel URL',
      firstQuartileHelperText: 'You may add up to 2 pixel URLs.',
      midpointLabel: 'Midpoint',
      midpointPlaceholder: 'Enter Pixel URL',
      midpointHelperText: 'You may add up to 2 pixel URLs.',
      thirdQuartileLabel: 'Third Quartile',
      thirdQuartilePlaceholder: 'Enter Pixel URL',
      thirdQuartileHelperText: 'You may add up to 2 pixel URLs.',
      completeLabel: 'Complete',
      completePlaceholder: 'Enter Pixel URL',
      completeHelperText: 'You may add up to 2 pixel URLs.',
      wrongUrlErrorMessage: 'Entered URL is invalid kindly check and enter again',
      errorListInsert: 'Please, use only one video',
      emptyUrl: 'Please enter Video URL',
      info: 'Information',
      lastUpdated: 'Last Updated',
      created: 'Created',
      by: 'By',
      imageWidthWarning: 'OG Meta Image must be 600 pixels or wider. The most recommended OG Meta image size is 1200 pixels x 627 pixels.',
      imageWidthError: 'Image is {imageWidth} Pixel Wide. Some social media platforms may not display this image.',
      fields: {
        name: 'Name *',
        slug: 'Slug',
        status: 'Status',
        username: 'Username',
        mediaId: 'YouTube/Vimeo/MP4 URL',
        type: 'Type',
        title: 'Title',
        description: 'Description',
        streamingDate: 'Streaming Date',
        streamingTime: 'Streaming Time',
        externalContentId: 'External Content ID'
      },
      webBeacon: 'Web Beaconing',
      videoMetadata: 'Video Metadata',
      noConfig: 'No configuration required.',
      behaviors: 'Behaviors',
      activationsAndMoments: 'Activations and Moments',
      layout: 'Layout',
      layoutEditCaption: 'Controls the look and feel',
      defaultExperienceSettings: 'Default experience settings',
      iabKeyword: 'IAB Categories and Keywords',
      allowedAndDisallowed: 'Allowed & Disallowed',
      overrideActivationBehaviors: 'Override Activation level behaviors if they exist',
      muted: 'Set Production Video to mute on load',
      clickOutsideToCloseSam: 'When an Activation is open and not below the video player, clicking on the video will close Activation',
      autoPlayBehavior: 'Set Production Video to Autoplay',
      contentWalletBehavior: 'Enable Content Wallet',
      forcePortraitOrientation: 'Always show Activations below the Video',
      showPreviewThroughDetails: 'Keep showing Activation previews when an Activation is open unless they overlap',
      playThroughBehavior: 'Prevent the video from pausing when an Activation is opened',
      fullscreenDetailsInMobilePortrait: 'Sets Activations to fullscreen when opened in mobile portrait',
      metacontentWidth: 'Open activation width',
      metacontentWidthTooltip: 'Percentage of video width',
      max: 'Max: ',
      min: 'Min: ',
      useDefault: 'Use Default',
      imageInputHint: 'Supported files: jpg, jpeg, png, gif, svg',
      previewMetacontentWidthTooltip: 'You can change align and width of Activation',
      selectIABs: 'Select Targeted IAB Categories',
      selectBlacklistedIABs: 'Select Blacklisted IAB Categories',
      selectKeywords: 'Select Targeted Keywords',
      selectBlacklistedKeywords: 'Select Blacklisted Keywords',
      selectTheTypeOfDisplay: 'Select the Type of Display',
      preview: 'Preview',
      triggerSqueezebackPreview: 'Trigger Squeezeback when Activation is in Preview',
      programmaticSqueezeback: 'The programmatic ad size determines the percentage of the squeezeback',
      previewPadding: 'Preview/Ad Padding',
      maximumSqueeze: 'Maximum squeeze of the video frame',
      videoWidthProirity: 'If video Squeezeback is active, video width takes priority.'
    },
    editContentTimeline: {
      template: 'Template'
    },
    editLive: {
      sessionOffline: 'This live session is currently offline.',
      metacontentList: 'Activations List',
      confirmDialog: 'Are you sure you want to navigate to another page?',
      warningDialog: 'This Activation already exists.',
      selectTemplate: 'Select Template',
      addToGameSync: 'Add Live Activations to GameSync?',
      pushToGameSync: 'PUSH'

    },
    campaign: {
      heading: 'Campaigns',
      tagline: 'Keep track of your campaign performance',
      dateStartWarning: 'Start date cannot be the same as or past end date',
      dateEndWarning: 'End date cannot be the same as or older than start date',
      searchCampaigns: 'Search for Campaigns'
    },
    campaignOverview: {
      totalCamp: 'Total Campaign Statistics',
      currentCamp: 'Current Schedule Statistics',
      baseSchedule: 'Base Scheduling',
      tempSchedule: 'Temp Scheduling',
      active: 'Active',
      inactive: 'Inactive',
      summary: 'Campaign Summary'
    },
    interactive: {
      heading: 'Interactives',
      tagline: 'Keep track of your interactive performance'
    },
    editInteractive: {
      name: 'Interactive name',
      selectProdSam: 'Select Target Distribution/Activations',
      iframe: 'iFrame',
      image: 'Image',
      previewTooltip: 'Preview'
    },
    editCampaign: {
      campaignName: 'Campaign name',
      startDate: 'Start Date',
      endDate: 'End Date',
      targetContent: 'Set Target Distributions',
      setStartEndTime: 'Set the start and end time of the selected Activation(s) below',
      activationPrioritySettings: 'Activation Prioritization Settings',
      selectIABCategories: 'Select IAB Categories and Keywords',
      selectTargetedIABCategories: 'Select targeted IAB Categories and Keywords',
      selectBlockedIABCategories: 'Select blocked  IAB Categories and Keywords',
      samDisplaySettings: 'Activation Display Settings',
      useCustomSamSettings: 'Use custom Activation settings',
      targetIABCategories: 'Targeted IAB Categories and Keywords',
      targetUrl: 'Target URLs',
      enterUrl: 'Enter Target URLs',
      keywords: 'Keywords',
      campaignStatus: 'Campaign Status',
      pauseCampaign: 'Pause Campaign',
      unpauseCampaign: 'Unpause Campaign',
      basicSchedule: 'Base Schedule',
      totalCampaignStatistics: 'Total Campaign Statistics',
      currentScheduleStatistics: 'Current Schedule Statistics',
      tempSchedule: 'Temporary Schedule',
      active: 'Active',
      runImpressions: 'Run Impressions',
      weekly: 'Weekly',
      daily: 'Daily',
      samDisplaySetting: 'Activation Display Settings',
      samDuration: 'Duration of each Activation',
      samDisplaySameTime: 'Activations displayed at the same time',
      durationSamSeconds: 'Duration between Activations in seconds',
      matchByTags: 'Match Distributions Automatically',
      matchAllDistributions: 'Match all Distributions',
      autoMatchDistributions: 'Matching Distributions',
      overwrite: 'Overwrite Campaign',
      allowedDists: 'Allowed Distributions',
      blockedDists: 'Blocked Distributions',
      distributions: 'Distributions',
      sams: 'Activations',
      filterIAB: 'Target IAB Categories and Keywords',
      filterBlockedIAB: 'Block IAB Categories and Keywords'
    },
    wallet: {
      heading: 'Wallet',
      tagline: 'Manage wallet transactions',
      enableWallet: 'Enable Content Wallet'
    },
    customWallet: {
      heading: 'Custom Wallet',
      tagline: 'Customize wallet',
      enableCustomWallet: 'Enable Custom Wallet'
    },
    account: {
      heading: 'Edit Profile',
      tagline: 'Complete your profile',
      changePassword: 'Change Password',
      fields: {
        username: 'Username',
        name: 'Name',
        email: 'Email Address',
        phone: 'Phone Number',
        country: 'Country',
        street: 'Street Address',
        city: 'City',
        state: 'State',
        zip: 'Zip Code',
        currentPass: 'Current Password',
        newPass: 'New Password',
        confirmNewPass: 'Confirm New Password'
      }
    },
    geofencing: {
      createNew: 'Create new device or geotargeting rule',
      targetName: 'Targeting Name',
      samsTargetTitle: 'Activations for Targeting',
      samsTargetSubtitle: 'Select the Activations you want to target',
      locationTargeting: {
        title: 'Location Targeting',
        subtitle: 'Define the location(s) you want to target. You can target specific geographic areas in the map and then further target individuals based on specific characteristics in the Traffic Prioritization section below.',
        targetByRegion: 'Target by Region',
        targetByMetro: 'Target by Metro Area/DMA',
        targetByCity: 'Target by City and Zip Code',
        targetByState: 'Target by State'
      },
      deviceTargeting: {
        title: 'Device Targeting',
        subtitle: 'Define the device(s) you want to target.',
        chooseTarget: 'Choose the type of targeting',
        paragraph: 'You can target specific geographic areas in the map and then further target individuals based on specific characteristics in the Traffic Prioritization section below.',
        options: {
          ios: 'iOS based (iPad and iPhone)',
          android: 'Android based (Samsung, Pixel, Moto, etc.)',
          desktop: 'Desktop based (Macbook, PCs, Windows OS)'
        }
      }
    }
  },
  components: {
    monitoringWrapper: {
      station: 'Station',
      station_id: 'Station ID',
      state: 'State',
      acr_ref_id: 'ACR Engine ID',
      feed_env: 'Environment',
      on_air: 'Broadcasting',
      station_set: 'Station Set',
      facility: 'Facility',
      country: 'Country',
      status: 'Status',
      all: 'All',
      current: 'Current',
      dateRange: 'Date Range'
    },
    monitoringTable: {
      good: 'Good',
      bad: 'Bad',
      degraded: 'Degraded',
      maintenance: 'Maintenance',
      acknowledged: 'Acknowledged'
    },
    timeMode: {
      placeholder: 'Time Mode',
      timecode: 'Timecode',
      utc: 'UTC',
      date: 'Date'
    },
    filterSelect: {
      status: 'Status',
      published: 'Published',
      draft: 'Draft',
      archived: 'Archived',
      type: 'Type',
      video: 'Video',
      live: 'Live',
      channel: 'Channel',
      page: 'Page',
      adUnit: 'Ad Unit',
      audio: 'Audio',
      overlay: 'Overlay',
      dynamic: 'Dynamic'
    },
    eulaDialog: {
      header: 'Welcome to SourceSync, to continue using our software, you must agree to the terms of the license',
      notAccepted: {
        top: 'We understand, but unfortunately we cannot allow you to continue.',
        middle: 'Please reach out to your account manager or send an email to {email}'
      }
    },
    dateRangeSelector: {
      sevenDays: 'Last 7 Days',
      thirtyDays: 'Last 30 Days',
      sixtyDays: 'Last 60 Days',
      ninetyDays: 'Last 90 Days',
      thisMonth: 'This Month',
      custom: 'Custom'
    },
    propertyLogoSettings: {
      brandLogo: 'Brand Logo',
      light: 'Light',
      dark: 'Dark'
    },
    commonImageComponent: {
      replaceImage: 'Replace Image'
    },
    breadcrumbs: {
      activation: 'Activation',
      production: 'Production',
      distribution: 'Distribution'
    },
    statusBubble: {
      archived: 'Archived',
      draft: 'Draft',
      published: 'Published'
    },
    pulseOrgSum: {
      header: 'Overview',
      subheader: 'Organization Performance: by Day',
      caption: 'This represents the aggregate performance for the organization for the selected time period, grouped by day.'
    },
    pulseOrgSumTable: {
      header: 'Organization Performance',
      subheader: 'Organization Performance',
      caption: 'This represents the aggregate performance for the organization for the selected time period.'
    },
    pulseOrgDist: {
      header: 'Distribution Performance',
      subheader: 'This represents the aggregate performance for the Property for the selected time period, grouped by Distribution.',
      caption: ''
    },
    pulseOrgProd: {
      header: 'Production Performance',
      subheader: 'This represents the aggregate performance for the Property for the selected time period, grouped by Production.',
      caption: ''
    },
    pulseOrgAct: {
      header: 'Activation Performance',
      subheader: 'This represents the aggregate performance for the Property for the selected time period, grouped by Activation.',
      caption: ''
    },
    pusleOrgAd: {
      header: 'Advertisers',
      impressions: {
        header: 'Impressions',
        subheader: 'Organization Performance: by Advertiser, by Day',
        caption: 'This represents the aggregate performance for the organization for the selected time period, grouped by Advertiser, by Day.'
      },
      engagements: {
        header: 'Engagements',
        subheader: 'Organization Performance: by Advertiser, by Day',
        caption: 'This represents the aggregate performance for the organization for the selected time period, grouped by Advertiser, by Day.'
      }
    },
    pusleOrgAct: {
      header: 'Activations/SAMs',
      impressions: {
        header: 'Impressions',
        secondHeader: 'Top Impression: by Activation, by Day',
        subheader: 'Organization Performance: by SAM, by Day',
        caption: 'This represents the aggregate performance for the organization for the selected time period, grouped by SAM, by Day.'
      },
      engagements: {
        header: 'Engagements',
        secondHeader: 'Top Engagement: by Activation, by Day',
        subheader: 'Organization Performance: by SAM, by Day',
        caption: 'This represents the aggregate performance for the organization for the selected time period, grouped by SAM, by Day.'
      }
    },
    publishStatusIndicator: {
      published: 'Published',
      draft: 'Draft',
      archived: 'Archived'
    },
    collections: {
      activation: {
        overview: 'Overview',
        productions: 'Productions',
        campaigns: 'Campaigns',
        settings: 'Settings',
        builder: 'Builder',
        preview: 'Preview'
      },
      property: {
        overview: 'Overview',
        installations: 'Installations',
        users: 'Users',
        settings: 'Settings'
      },
      pulse: {
        overview: 'Overview',
        distributions: 'Distributions',
        activations: 'Activations',
        productions: 'Productions'
      },
      campaign: {
        dashboard: 'Dashboard',
        table: 'Campaign List',
        create: 'Create New Campaign'
      },
      editActivations: {
        settingsTab: {
          general: 'General',
          advertising: 'Advertising',
          style: 'Style',
          targeting: 'Targeting',
          behaviors: 'Behaviors',
          sharing: 'Sharing',
          developerSettings: 'Developer Settings'
        },
        overviewTab: {
          createdOn: 'Created On',
          lastUpdated: 'Last Updated',
          createdBy: 'Created By',
          activationExperience: 'Link to Activation Experience',
          type: 'Type',
          contentEnhancement: 'Content Enhancement',
          active: 'Active',
          advertiser: 'Advertiser',
          replaceImage: 'Replace the Activation image',
          summary: 'Summary',
          lastWeek: 'Last 7 Days',
          impressions: 'Impressions',
          engagements: 'Engagements',
          topProductions: 'Top Productions',
          noProductions: 'No Productions',
          goToProduction: 'Go to Production',
          topCampaigns: 'Top Campaigns',
          noCampaigns: 'No Campaigns',
          goToCampaign: 'Go to Campaign'
        }
      },
      editProduction: {
        overview: 'Overview',
        distributions: 'Distributions',
        activations: 'Activations',
        settings: 'Settings',
        settingsTab: {
          general: 'General',
          targeting: 'Targeting',
          behaviors: 'Behaviors',
          sharing: 'Sharing'
        }
      },
      editDistribution: {
        overview: 'Overview',
        campaigns: 'Campaigns',
        activations: 'Activations',
        settings: 'Settings',
        topCamps: 'Top Campaigns',
        goToLink: 'Go to Distribution URL',
        settingsTab: {
          general: 'General',
          targeting: 'Targeting',
          behaviors: 'Behaviors',
          sharing: 'Sharing'
        }
      }
    },
    trackingPixelsEditor: {
      previewUrl: 'URL for Preview',
      detailsUrl: 'URL for Details'
    },
    orgList: {
      title: 'Clone {name} to...',
      titleMove: 'Move {name} to...'
    },
    vastTag: {
      warningMsgProductionOverlay: 'The production assigned to this distribution needs to be an overlay. Change the production to overlay if you want to add VAST Tag.',
      warningMsgDistributionOverlay: 'This distribution needs to be an overlay. Change it to overlay if you want to add VAST Tag.',
      copyVastTagTooltip: 'Copy VAST tag',
      fetchMode: 'Fetch mode',
      disableVpaid: 'Remove VPAID',
      label: 'VAST Tag',
      useMp4File: 'To create a vast tag, the productions url must be an .mp4 file',
      useMp4FileDistribution: 'To create a vast tag, the distribution url must be an .mp4 file',
      emptyTimeline: 'Add Activations to the timeline'
    },
    conversionPixel: {
      copy: 'Copy',
      download: 'Download',
      label: 'Conversion Pixel'
    },
    cdnVideoToolbar: {
      onSyncSuccessful: 'Sync successfully requested',
      lastUpdated: 'Last Updated: {sync}',
      notSynced: 'Not synced',
      syncInProgress: 'Syncing in progress...'
    },
    addYourVideosDialog: {
      title: 'Add Your Videos',
      selectCdn: 'Select your CDN:',
      addChannelId: 'Add your YouTube Channel ID',
      channelId: 'Channel ID',
      syncTitle: 'If you link your channel, you will be able to sync videos and enrich your content by associating Activations against them.',
      syncSub: 'The imported videos will generate Productions and Distributions.',
      publishVideosTitle: 'Your account is successfully linked. Would you like to sync your videos now? Sync request may take a while.',
      onCloseTitle: 'Are you sure you want to quit?',
      onCloseMsg: 'The elements that were synced will not be saved.'
    },
    resyncVideosDialog: {
      title: 'Re-Sync Videos',
      publishVideosTitle: 'Would you like to resync your videos?'
    },
    geofencingTargetDialog: {
      targetNaming: {
        title: 'Name of Targeting',
        subtitle: 'Please enter the name of Targeting to continue',
        targetName: 'Targeting Name'
      },
      targetType: {
        title: 'Type of Targeting',
        subtitle: 'What type of targeting do you want to create?',
        chooseTargetType: 'Choose the type of targeting',
        location: 'Location Targeting',
        device: 'Device Targeting'
      }
    },
    locationTargetRegion: {
      banner: 'Search and select the region/state to move forward',
      searchRegionTitle: 'Search for one or more Regions to add',
      searchRegionSubtitle: 'Type in one or more Regions to add for targeting',
      searchRegionPlaceholder: 'Type to search for a Region',
      searchStatePlaceholder: 'Type to search for a State',
      searchStateTitle: 'Search for one or more States to add',
      searchStateSubtitle: 'Type in one or more States to add for targeting',
      removeStateTitle: 'Search for one or more States to remove',
      removeStateSubtitle: 'Type in one or more States to remove from the total States of selected Country',
      or: 'OR',
      targetAll: 'Target all Regions'
    },
    locationTargetMetro: {
      banner: 'Search and select the region/state/Metro Area to move forward',
      removeMetroTitle: 'Search for one or more Metro Area/DMAs to remove',
      removeMetroSubtitle: 'Type in one or more Metro Area/DMAs to remove from the selected States above',
      searchMetroPlaceholder: 'Type to search for a Metro Area/DMAs',
      targetAllMetro: 'Taget all Metro Area/DMAs',
      addMetroTitle: 'Search for one or more Metro Area/DMAs to add',
      addMetroSubtitle: 'Type in one or more Metro Area/DMAs to add from the selected States above',
      searchStateSubtitle: 'Type in one or more States to add for targeting Metro Area/DMA'
    },
    locationTargetCity: {
      banner: 'Search and select the region/state/City/Zip to move forward',
      cityHeading: 'This will target some of the Cities from the selected States',
      stateHeading: 'This will target all Cities of the States that you select',
      zipHeading: 'This will target all Zip codes of the Cities that you select',
      zipCodeHeading: 'This will target some of Zip codes from the selected Cities',
      eliminateText: 'Add or Eliminate Cities from the States that you\'ve chosen to target',
      searchStateSubtitle: 'Type in one or more States to add for targeting City and Zip codes',
      searchZipCityTitle: 'Search for one or more cities (from the selected States) to add',
      searchZipCitySubtitle: 'Type in one or more cities to add',
      searchZipCityTitleEliminate: 'Search for one or more cities (from the selected States) to eliminate',
      searchZipCitySubtitleEliminate: 'Type in one or more cities to eliminate',
      addZipCodeText: 'Add Zip Codes from the Cities that you\'ve chosen to select',
      searchZipCodeTitle: 'Search for one or more Zip Codes (from the selected Cities) to add',
      searchZipCitySubtitleAdd: 'Type in one or more zip codes to add',
      searchZipCitySubtitleRemove: 'Type in one or more zip codes to eliminate',
      searchZipCodeTitleRemove: 'Search for one or more Zip Codes (from the selected Cities) to eliminate',
      searchCityPlaceholder: 'Type to search for a city',
      searchZipCodePlaceholder: 'Type to search for a zip code'
    },
    locationTargetState: {
      banner: 'Search and select the state to move forward',
      removeStateSubtitle: 'Type in one or more States to remove from the total States of selected Country'
    },
    campaignTempScheduleDialog: {
      title: 'Change schedule Temporarily',
      warningBanner: '*The Start and the End date should be between {startDate} and {endDate}. This will not change the original campaign, it will just change the settings of current campaign'
    },
    geofencingTable: {
      header: 'Current Targeting Rules',
      rules: {
        byState: 'By State',
        byRegion: 'By Region',
        byCity: 'By City'
      }
    },
    bannerAdSection: {
      adsInASam: 'Programmatic Advertising',
      enableAds: 'Programmatic Ads On',
      disable: 'Programmatic Ads Off',
      headers: {
        publisherInfo: 'Publisher Information',
        adSettingsPlacements: 'Ad Settings & Placements',
        samPreviewStyle: 'Activation Preview Style',
        adAndContentPriority: 'Ads and Content Prioritization Settings',
        displaySettings: 'Ad and Activation Display Settings',
        displayAdsSettings: 'Ad Display Settings',
        displayOptions: 'Display Options'
      },
      adAndContentSubtitle: 'Number of Activation types displayed at the same time',
      samsDisplayedAtTheSameTime: 'Activations displayed at the same time',
      content: 'Content',
      ads: 'Ads',
      position: 'Position',
      maxContent: 'Set Maximum Number of Content Activations',
      maxAds: 'Set Maximum Number of Ad Activations',
      maxNumAds: 'Maximum Number of Ads',
      dynamicFill: 'Dynamic Fill',
      maxExistingSams: 'Max existing number of Activations',
      maxNumContent: 'Maximum Number of Content Activations',
      domain: 'Domain*',
      publisherId: 'Publisher Id',
      targetPage: 'Target Page*',
      adPartner: 'Advertising Partner',
      samSize: 'Activation Size',
      openSam: 'Open Activation',
      defaultSam: 'Default Activation',
      warning: 'Ad Activation settings that were previously saved on the Production or Distribution levels will be overridden',
      tooltip: {
        openSam: 'Activation will render in open form',
        defaultSam: ' Activation will open in icon mode'
      },
      adSize: 'Ad Sizes',
      dynamic: 'Dynamic',
      manual: 'Manual',
      samType: 'Activation Type',
      videoType: 'Video Activation',
      bannerType: 'Banner Ads',
      jsonInput: 'JSON Input',
      url: 'URL',
      genericProvider: 'VAST URL',
      scriptUrl: 'Script URL',
      slot: 'Slot Name',
      hideUntilReady: 'Hide Until Ready',
      requireInViewport: 'Require In Viewport'
    },
    replaceAssetDialog: {
      dragAndDrop: 'Drag and drop your replacement here',
      upload: 'Upload from Computer',
      affected: 'Affected Resources',
      warning: 'The asset will be replaced across your account',
      inputWarning: 'Cannot have uploaded file',
      fileNoSupport: 'File not supported',
      supported: 'Supported files: ',
      lowercaseOr: 'or',
      urlVideoInput: 'Image/Video URL',
      urlImgInput: 'Image URL'
    },
    deleteAssetDialog: {
      warning: 'The asset will be deleted across your account',
      title: 'Delete Assets'
    },
    uploadProgressViewer: {
      fileLoading: 'file loading',
      filesLoading: 'files loading',
      dragAndDrop: 'Drag and drop your assets here'
    },
    campaignOverwriteDialog: {
      title: 'Select Campaigns and Distributions to Overwrite'
    },
    recentsListHeader: {
      show: 'Show',
      activations: 'Latest Activations',
      campaigns: 'Recent Campaigns'
    },
    recentsListChartItem: {
      id: 'ID: {id}'
    },
    recentsListChart: {
      noData: 'No data'
    },
    recentAnalyticsChart: {
      performance: 'Performance'
    },
    collectionWizard: {
      campaign: {
        details: {
          label: 'Campaign Details',
          header: 'Create Campaign',
          campaignName: 'Campaign Name'
        },
        distributions: {
          label: 'Distributions',
          header: 'Distributions',
          subtitle: 'In this section, you can either match your distributions automatically or manually attach them to this campaign.'
        },
        ads: {
          label: 'Programmatic Ads',
          header: 'Programmatic Ads',
          caption: 'OPTIONAL'
        },
        activations: {
          label: 'Manage Activations',
          header: 'Manage Activations',
          caption: 'OPTIONAL',
          sams: 'Activations'
        },
        targeting: {
          label: 'Targeting',
          header: 'Targeting',
          caption: 'OPTIONAL',
          subtitle: 'Target URLs, IAB Categories and Keywords, or create location and geotargeting rules.'
        },
        summary: {
          label: 'Campaign Summary',
          header: 'Campaign Summary',
          enabled: 'Enabled',
          disabled: 'Disabled',
          dates: 'Dates',
          impressions: 'Impressions',
          impressionsPerDay: 'Impressions per day',
          impressionsPerWeek: 'Impressions per week',
          daily: 'Daily',
          weekly: 'Weekly',
          uncapped: 'Uncapped',
          total: 'total',
          targeted: 'targeted',
          blocked: 'blocked',
          matchDists: 'Match Distributions Automatically',
          overwriting: 'Campaign Overwriting',
          activationsOnCampaign: 'on a campaign level',
          withinProd: 'within included Productions and Distributions',
          activationTableLabel: 'Start and End Time of the Selected Activation(s) Below',
          durationOfEach: 'Duration of each Activation in seconds',
          durationBetween: 'Duration between Activations in seconds',
          positionOfAll: 'Position of All Activations',
          prioritizationSetting: 'Activation Prioritization Settings',
          high: 'High',
          medium: 'Medium',
          low: 'Low',
          associateWithTimeline: 'Associate with Timeline',
          associateWithTags: 'Associate with Tags',
          none: 'None',
          iabCat: 'IAB Categories',
          keywords: 'Keywords',
          notAPartOfCampaign: 'Not selected as part of this campaign',
          noAdditionalSams: 'No additional Activations added to this campaign',
          noTargets: 'There are no targeting rules that are a part of this campaign',
          selectedSizes: 'Selected Sizes',
          previewStyle: 'Preview Style',
          samsAtATime: 'Activations at the Same Time',
          maxContent: 'Maximum Content Activations',
          targetRules: 'targeting rules',
          deviceRules: 'device targeting',
          allowedIABCat: 'Allowed IAB Categories'
        }
      }
    },
    topDists: {
      header: 'Top Distributions',
      engagements: 'Engagements',
      impressions: 'Impressions'
    },
    topProds: {
      header: 'Top Productions',
      engagements: 'Engagements',
      impressions: 'Impressions'
    },
    topCamps: {
      header: 'Top Campaigns',
      engagements: 'Engagements',
      impressions: 'Impressions'
    },
    topActs: {
      header: 'Top Activations',
      engagements: 'Engagements',
      impressions: 'Impressions',
      noData: 'No data to display',
      unknown: 'Unknown',
      activation: 'Activation',
      campaign: 'Campaign',
      production: 'Production'
    },
    layoutPreset: {
      header: 'Layout Presets For Embedder',
      selectPreset: 'Select Preset',
      caption: 'Look and Feel when sharing on social media or messaging apps'
    },
    campaignCalendarWidget: {
      ongoing: 'Ongoing',
      live: 'Going Live',
      ending: 'Ending',
      paused: 'Paused'
    },
    campaignDashboard: {
      analytics: 'Analytics',
      calendar: 'Calendar',
      liveCampaigns: 'Live Campaigns'
    },
    propertySettings: {
      headers: {
        platform: 'Platform',
        production: 'Production',
        activation: 'Activation'
      },
      general: 'General',
      categoriesAndKeywords: 'Categories & Keywords',
      behaviors: 'Behaviors',
      layout: 'Style/Layout',
      style: 'Styles'
    },
    domainsList: {
      domains: 'Domains ({sum})',
      addDomain: 'Add a Domain'
    }
  },
  categories: {
    title: 'Categories',
    processingCategories: 'Processing in progress'
  },
  tooltips: {
    automatedSam: 'Automated Activation'
  },
  productionTimeline: {
    keywords: {
      title: 'Keywords',
      dropDownLabel: 'Keywords'
    },
    customIABCategories: {
      title: 'IAB Categories',
      dropDownLabel: 'IAB Categories'
    },
    events: {
      title: 'Events',
      dropDownLabel: 'Existing Activation'
    },
    createNewSam: 'New Activation'
  }
}
